<template xmlns="">
  <div class="rules-container">
    <div class="filter-panel">
      <CSSelect style="margin-right: 0; width: 185px">
        <el-date-picker
            v-model="conditions.startTime"
            style="width: 157px"
            format="yyyy-MM-dd"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            :editable="false"
            @change="formatStartTime"
        >
        </el-date-picker>
      </CSSelect>

      <span class="inline-gray"></span>

      <CSSelect style="width: 185px">
        <el-date-picker
            v-model="conditions.endTime"
            style="width: 157px"
            format="yyyy-MM-dd"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            :editable="false"
            @change="formatEndTime"
            :picker-options="pickerOptionsEnd"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect>
        <select v-model="conditions.building">
          <option value="">所有楼栋</option>
          <option
              v-for="name in buildingData"
              :key="name.id"
              :value="name.id"
          >
            {{ name.name }}
          </option>
        </select>
      </CSSelect>
      <div
          style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
            type="text"
            placeholder="搜索业主/租客"
            v-model="conditions.queryInput"
            style="
            color: #000;
            width: 320px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
        />
      </div>
      <button type="button" class="btn btn-primary btn-sm" @click="query()">
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel">
            <span>已缴费面积: {{ feeRulesData.alreadyPayCost }}m<sup>2</sup></span>
            <span>未缴费面积：{{ feeRulesData.notPayCost }}m<sup>2</sup></span>
            <span>已缴费面积占比：{{ feeRulesData.proportion }}%</span>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>房间号</th>
            <th>所在楼栋</th>
            <th>所在楼层</th>
            <th>业主/租客</th>
            <th>建筑面积(m<sup>2</sup>)</th>
            <th>已缴费账单</th>
            <th>已缴费面积(m<sup>2</sup>)</th>
            <th>未缴费面积(m<sup>2</sup>)</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in feeRulesData.feeRulesList" :key="item.id">
            <td>{{ item.roomNo }}</td>
            <td>{{ item.buildingName }}</td>
            <td>{{ item.floor }}</td>
            <td>{{ item.lesseeName }}</td>
            <td>{{ item.areaOfStructure }}</td>
            <td>{{ item.billTotal }}</td>
            <td>{{ item.alreadyPayCost }}</td>
            <td>{{ item.notPayCost }}</td>
          </tr>
        </template>
      </CSTable>
      <!-- //分页 -->
      <!--      <Pagination name="pagination" componentName="Pagination"></Pagination>-->
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import CSTable from "@/components/common/CSTable";

import {
  queryBuildingUrl, queryAlreadyPayCostUrl,
} from "@/requestUrl";

export default {
  components: {
    CSSelect,
    CSTable,
    Pagination
  },
  data() {
    return {
      pag: 1,
      total: "-",
      filterHeight: "",
      buildingData: [],
      conditions: {
        startTime: dayjs().subtract(1, 'month').startOf("month").format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs().subtract(1, 'month').endOf('month').format("YYYY-MM-DD 23:59:59"),
        queryInput: "",
        building: "",
      },
      feeRulesData: {
        alreadyPayCost: null,
        notPayCost: null,
        proportion: null,
        feeRulesList: []
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.conditions.startTime) {
            return (
                time.getTime() < new Date(this.conditions.startTime.slice(0, 10)).getTime() - 8.64e7
            );
          }
        }
      },
    }
  },
  methods: {
    formatStartTime(val) {
      this.conditions.startTime = val + " 00:00:00";
    },
    formatEndTime(val) {
      this.conditions.endTime = val + " 23:59:59";
    },
    // 查询所有楼栋
    getBuildings() {
      this.$fly
          .post(queryBuildingUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            name: "",
            code: "",
            id: "",
          })
          .then((res) => {
            this.buildingData = res.data.datas;
          });
    },
    // 查询
    query() {
      this.$fly
          .post(queryAlreadyPayCostUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            startDate: this.conditions.startTime, //开始区间
            endDate: this.conditions.endTime, //结束时间
            building: this.conditions.building,
            search: this.conditions.queryInput //搜索框内容
          })
          .then((response) => {
            if (response.code !== 0) {
              return;
            }
            console.log(response)
            this.feeRulesData.alreadyPayCost = response.data.alreadyPayCost
            this.feeRulesData.notPayCost = response.data.notPayCost
            this.feeRulesData.proportion = response.data.proportion
            this.feeRulesData.feeRulesList = response.data.feeRulesList;
          });
    }
  },
  mounted() {
    this.getBuildings()
    this.query()
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  }
}
</script>

<style>
.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}

.table-header-panel span {
  margin-right: 80px;
}

.img-w {
  width: 20px;
  height: 20px;
}

.img-w img {
  width: 100%;
  height: 100%;
}

.disClss {
  background: #CCCCCC;
  border: none;
}
</style>
